import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import computer from "../medias/computer.png";
import laptop from "../medias/laptop.png";
import screen from "../medias/screen.png";

class ResultElement extends Component {

	gimmeMeImg (title) {
		switch (title) {
		case "Ordinateurs de bureau":
			return computer;
		case "Ordinateurs portables":
			return laptop;
		case "Ecrans":
			return screen;
		default:
			return null;
		}
	}
	render () {
		const { element, index } = this.props;
		return (
			<FlexboxGrid  key={element.title} style={index < 2 ? {borderBottom: "1px solid #69aec4", margin: "10px"} : null}>
				<FlexboxGrid.Item colspan={6} style={{ textAlign: "center"}}>
					<img src={this.gimmeMeImg(element.title)} alt={element.title} className="resultImage" />
					<p className="resultTitle">{element.title}</p>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={9} style={{ textAlign: "left"}}>
					<p className="resultElement">{"Impact GES: "}<b>{element.GESimpact}</b>{" kg eq. CO2/an"}</p>
					<p className="resultElement">{"Potentiel d'amélioration: "}<b>{element.GESecart}</b>{" %"}</p>
				</FlexboxGrid.Item>
				<FlexboxGrid.Item colspan={9} style={{ textAlign: "left"}}>
					<p className="resultElement">{"Impact EP: "}<b>{element.MPimpact}</b>{" kWh EP/an"}</p>
					<p className="resultElement">{"Potentiel d'amélioration: "}<b>{element.MPecart}</b>{" %"}</p>
				</FlexboxGrid.Item>
			</FlexboxGrid>
		);
	}
}
export default ResultElement;
