import React, { Component } from 'react';
import fullCircle from '../medias/circular-shape-silhouette.png';
import emptyCircle from '../medias/circle-shape-outline.png';

class Stars extends Component {

    onAction(data) {
        this.props.onAction(data);
    }

    giveMeTheStars(nbStars, answerID) {

        let list = [];

        for (var i = 0; i < nbStars; i++) {
            list.push(
                <span className="col valign-wrapper voteStarContainer" key={`fullStar${answerID}${i}`}>
                    <img
                        src={fullCircle}
                        alt="fullCircle"
                        className="voteStar"
                        onClick={this.onAction.bind(this, i + 1)}
                    />
                </span>
            )
        }
        for (var j = 0; j < (5 - nbStars); j++) {
            list.push(
                <span className="col valign-wrapper voteStarContainer" key={`emptyStar${answerID}${j}`}>
                    <img
                        src={emptyCircle}
                        alt="emptyCircle"
                        className="voteStar"
                        onClick={this.onAction.bind(this, parseInt(nbStars) + j + 1)}
                    />
                </span>
            )
        }

        return list;
    }
    render() {
        return (
            <div className="row valign-wrapper voteContainer">
                {this.giveMeTheStars(this.props.score, this.props.answerID)}
            </div>
        );
    }
}

export default Stars;


