import React, { Component } from 'react';
import { Checkbox } from 'rsuite';

class CheckboxA extends Component {

    onAction() {
        this.props.onAction(null);
    }

    render() {
        const { answer } = this.props;
        return (
            <Checkbox
                id={`check${answer.id}`}
                checked={answer.checked ? true : false}
                onChange={this.onAction.bind(this)}
            >
                <span className="answerText">{answer.title}</span>
                {this.props.demo ? <span className="demo">{`(A${answer.id})`}</span> : null}
            </Checkbox>
        );
    }
}

export default CheckboxA;
