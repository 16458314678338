import React from "react";
import { Icon } from 'rsuite';

const MessageInformez = (message) => {
	return (
		<div style={{
			margin: '10px 20px',
			backgroundColor: '#69adc436',
			borderRadius: '5px',
			padding: '10px',
			textAlign: 'justify'
		}}>
			<Icon
				icon="info"
				size="lg"
				style={{
					marginRight: '20px'
				}} />
			<span dangerouslySetInnerHTML={{ __html: message.message }}
				style={{
					fontSize: '1.2em'
				}}
			/>
		</div>
	);
};

export default MessageInformez;