import React, { Component } from 'react';
import { Row, Col } from 'rsuite';
import RadioA from './radioA';
import Checkbox from './checkboxA';
import Vote from './vote';
import Simple from './simple';
import TextInp from './textInput';
import TextAreaInp from './textAreaInput';
import IntInp from './intInput';
import PercentInp from './percentInput';
import Select from './select';

class Answer extends Component {

    upTheBlocks(answerUP) {
        this.props.upTheBlocks(answerUP);
    }

    onAction(data) {

        let answerUP = this.props.answer;

        //Sauvegarde de la data si besoin
		if (data) {
			answerUP.value = data;
        }
        
        //Checked
		if (answerUP.type === 'checkbox') {
			answerUP.checked = !answerUP.checked;

		} else if (answerUP.type !== 'radio') {
            answerUP.checked = true;
		}

        this.upTheBlocks(answerUP);
    }

    onData(data) {

        let answerUP = this.props.answer;

        //Sauvegarde de la data si besoin
		if (data) {
			answerUP.value = data;
        }

        //Checked
        if (answerUP.type === 'simple') {
            answerUP.checked = true;
		}

        this.upTheBlocks(answerUP);
    }

    //Selection du component en fonction du type
    giveMeTheCompenent(questionRank, answer) {
        switch (answer.type) {
            case 'radio':
                return <RadioA answer={answer} questionRank={questionRank} onAction={this.onAction.bind(this)} demo={this.props.demo} />;
            case 'checkbox':
                return <Checkbox answer={answer} onAction={this.onAction.bind(this)} demo={this.props.demo} />;
            case 'vote':
                return <Vote answer={answer} onAction={this.onAction.bind(this)} demo={this.props.demo} />;
            case 'simple':
                return <Simple answer={answer} demo={this.props.demo} />;
            case 'trigger':
                return <Simple answer={answer} demo={this.props.demo} />;
            case 'select':
                return <Select answer={answer} onAction={this.onAction.bind(this)} demo={this.props.demo} />;
            default:
                return null;
        }
    }

    //Selection du type d'input, afin de controller le type de donnees qui peut y etre entre
    giveMeTheInput(answer) {
        switch (answer.input) {
            case 'text':
                return <TextInp answer={answer} onData={this.onData.bind(this)} />
            case 'textarea':
                return <TextAreaInp answer={answer} onData={this.onData.bind(this)} />
            case 'integer':
                return <IntInp answer={answer} onData={this.onData.bind(this)} />
            case 'percent':
                return <PercentInp answer={answer} onData={this.onData.bind(this)} />
            default:
                return null;
        }
    }

    render() {
        const { answer } = this.props;
        return (
            <Row className="show-grid answerContent" key={answer.id}>
                {answer.title !== null ?
                    <Col xs={24} className="answerSubContent">
                        {/* Selection component adequat */}
                        {this.giveMeTheCompenent(this.props.questionRank, answer)}
                    </Col>
                    : null
                }
                {answer.input ?
                    <Col xs={22} className="answerSubContent">
                        {/* Selection type d'input */}
                        {this.giveMeTheInput(answer)}
                    </Col>
                    : null}
            </Row>
        );
    }
}

export default Answer;


