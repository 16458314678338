import React, { Component } from 'react';
import { Input } from 'rsuite';

class TextAreaInp extends Component {

    onData(event) {
        this.props.onData(event.target.value);
    }

    componentDidMount() {
        if (this.props.answer.value) {
            document.getElementById(`input${this.props.answer.id}`).value = this.props.answer.value;
        }
    }

    render() {
        const { answer } = this.props;
        return (
            <div className="answerInput">
                <Input
                    id={`input${answer.id}`}
                    componentClass="textarea"
                    rows={3}
                    style={{ width: 'auto', resize: 'auto' }}
                    onBlur={this.onData.bind(this)}
                />
            </div>
        );
    }

};

export default TextAreaInp;
