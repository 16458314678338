import React, { Component } from "react";
import { Icon, Whisper, Tooltip } from "rsuite";
import PercentInp from "./percentInput";
import Select from "./select";
import Trigger from "./trigger";

class AnswerTrigger extends Component {

	upTheBlocks(answerUP) {
		this.props.upTheBlocks(answerUP);
	}

	onAction(data) {

		let answerUP = this.props.answer;

		//Sauvegarde de la data si besoin
		if (data) {
			answerUP.value = data;
		}

		//Checked
		if (answerUP.type === 'checkbox') {
			answerUP.checked = !answerUP.checked;

		} else if (answerUP.type !== 'radio') {
			answerUP.checked = true;
		}

		this.upTheBlocks(answerUP);
	}

	onData(data) {

		let answerUP = this.props.answer;

		//Sauvegarde de la data si besoin
		if (data !== null) {
			answerUP.value = data;
		}

		//Checked
		answerUP.checked = true;

		this.upTheBlocks(answerUP);
	}

	defaultAnswer(value) {
		let answerUP = this.props.answer;

		answerUP.value = value;
		answerUP.checked = false;

		this.upTheBlocks(answerUP);
	}

	onTrigger() {
		let ID = this.props.answer.id;

		if (ID === 69 || ID === 73 || ID === 77) {
			if (this.props.trigger) { //Trigger inactif => Valeur par défaut
				this.defaultAnswer(null);
			} else { //Trigger actif => reset pour que l'utilisateur entre ses données
				if (this.props.answer.value) {
					this.onData(this.props.answer.value);
				} else {
					this.onData(4);
				}
			}
		} else {
			if (this.props.trigger) { //Trigger inactif => Valeur par défaut
				this.defaultAnswer(null);
			} else { //Trigger actif => reset pour que l'utilisateur entre ses données
				if (this.props.answer.value) {
					this.onData(this.props.answer.value);
				} else {
					this.onData(0);
				}
			}
		}
		this.props.onTrigger();
	}

	render() {
		const { answer } = this.props;
		return (
			<div className="answerContent" key={answer.id}>
				<div className="simpleContainer" key={answer.id}>
					<span className="answerText"> {answer.title}</span>
					{answer.message ?
						<Whisper
							trigger="hover"
							placement="right"
							speaker={
								<Tooltip>
									{answer.message}
								</Tooltip>
							}
						>
							<Icon icon="question-circle2" size="lg" className="helperIcon" />
						</Whisper>
						: null}
					{this.props.demo ? <span className="demo">{`(A${answer.id})`}</span> : null}
				</div>
				<div className="answerSubContent">
					<div className="triggerElement">
						<Trigger trigger={this.props.trigger} onTrigger={this.onTrigger.bind(this)} />
					</div>
					<div className="triggerElement">
						{answer.type === "select" ?
							<Select
								answer={answer}
								onAction={this.onAction.bind(this)}
								demo={this.props.demo}
								trigger={this.props.trigger}
							/>
							:
							<PercentInp answer={answer} onData={this.onData.bind(this)} trigger={this.props.trigger} />
						}
					</div>
				</div>
			</div>
		);
	}
}

export default AnswerTrigger;


