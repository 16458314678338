import React, { Component } from "react";
import { SelectPicker } from "rsuite";

class Select extends Component {
	onAction(value) {
		this.props.onAction(value);
	}

	renderOptions() {

		let list = [{
			label: "",
			value: null
		}];

		for (var i = 0; i < 12; i++) {
			list.push(
				{
					label: `${i + 1} ${i === 0 ? "an" : "ans"}`,
					value: i + 1
				}
			);
		}
		return list;
	}

	render() {
		const { answer } = this.props;
		
		return (
			<SelectPicker
				id={`input${answer.id}`}
				data={this.renderOptions()}
				searchable={false}
				onSelect={this.onAction.bind(this)}
				placeholder={!answer.checked ? "Années" : null}
				value={answer.value ? answer.value : null}
				disabled={!this.props.trigger}
			/>
		);
	}
}

export default Select;


