import React, { Component } from "react";
import { Toggle } from "rsuite";

class Trigger extends Component {
    onTrigger() {
        this.props.onTrigger();
    }

    render() {
        return (
        <>
        <span className="triggerText">Je dispose des informations </span>
        <span>
        <Toggle
                size="lg"
                checkedChildren="Oui"
                unCheckedChildren="Non"
                onChange={this.onTrigger.bind(this)}
                checked={this.props.trigger}
            />
        </span>
        </>
        )
	}
}

export default Trigger;


