/*
* Liste des blocs ordonnée, pour chaque bloc:
*   component => le type
*   id => l'id du bloc en BDD
*   progression => le pourcentage de progression à afficher
*/

const routes = [
    {
        component: 'introduction',
        id: null,
        progression: null
    },
    {
        component: 'question',
        id: 1,
        progression: 0
    },
    {
        component: 'question',
        id: 2,
        progression: 5
    },
    {
        component: 'question',
        id: 3,
        progression: 10
    },
    {
        component: 'question',
        id: 4,
        progression: 15
    },
    {
        component: 'informezResults',
        id: 4,
        progression: 15
    },
    {
        component: 'question',
        id: 5,
        progression: 30
    },
    {
        component: 'question',
        id: 5,
        progression: 30
    },
    {
        component: 'question',
        id: 6,
        progression: 50
    },
    {
        component: 'question',
        id: 7,
        progression: 60
    },
    {
        component: 'question',
        id: 8,
        progression: 70
    },
    {
        component: 'resultats',
        id: null,
        progression: null
    },
    {
        component: 'question',
        id: 9,
        progression: 80
    },
    {
        component: 'question',
        id: 12,
        progression: 90
    },
    {
        component: 'question',
        id: 10,
        progression: 95
    },
    {
        component: 'user',
        id: null,
        progression: 100
    },    
    {
        component: 'message',
        id: 11,
        progression: null
    },
];

export default routes;