import React, { Component } from 'react';
import { Radio, Checkbox, Icon, Row, Col } from 'rsuite';
import MessageInformez from './messageInformez';

class ResultsInformez extends Component {

    listGoodAnswers = [
        {
            questionID: 10,
            goodAnswers: [0],
            goodMessage: "Et oui, c'est la fabrication des équipements qui concentre, de loin, les principaux impacts du numérique. Selon une <a href='https://www.greenit.fr/empreinte-environnementale-du-numerique-mondial/' target='_blank' rel='noopener noreferrer'> étude</a> publiée en octobre 2019 par GreenIT.fr, elle est responsable de 76 % de l’épuisement des ressources abiotiques, de 75 % des tensions sur l’eau douce et de 40% des émissions de Gaz à effet de serre imputables au numérique mondial.",
            wrongMessage: "Et non, c'est la fabrication des équipements qui concentre, de loin, les principaux impacts du numérique. Selon une <a href='https://www.greenit.fr/empreinte-environnementale-du-numerique-mondial/' target='_blank' rel='noopener noreferrer'> étude</a> publiée en octobre 2019 par GreenIT.fr, elle est responsable de 76 % de l’épuisement des ressources abiotiques, de 75 % des tensions sur l’eau douce et de 40% des émissions de Gaz à effet de serre imputables au numérique mondial."
        },
        {
            questionID: 11,
            goodAnswers: [0, 1, 2, 3, 4],
            goodMessage: "Il fallait effectivement cocher toutes les cases ! De l'eutrophisation des milieux aquatiques, à l'acidification de l'eau et du sol, en passant par l'épuisement des ressources abiotiques et de très nombreuses conséquences négatives sur la biodiversité, les impacts environnementaux du numérique sont nombreux et très diversifiés. ",
            wrongMessage: "Il fallait cocher toutes les cases ! De l'eutrophisation des milieux aquatiques, à l'acidification de l'eau et du sol, en passant par l'épuisement des ressources abiotiques et de très nombreuses conséquences négatives sur la biodiversité, les impacts environnementaux du numérique sont nombreux et très diversifiés."
        },
        {
            questionID: 12,
            goodAnswers: [4],
            goodMessage: "Bravo, Jusqu’à 700 fois! En éco-concevant les services numériques on réduit la puissance informatique (RAM, CPU, bande passante, nombre de serveurs, etc.) nécessaire au fonctionnement du service. La compréhension fine des besoins des usagers des services est également déterminante pour adapter les fonctionnalités aux attentes réelles de ces derniers et ne pas alourdir les services de contenus et de fonctionnalités inutiles : le gras numérique. La pensée Design est donc fondamentale pour construire une architecture en juste équilibre avec les besoins des citoyens",
            wrongMessage: "Et non, bien plus, jusqu’à 700 fois! En éco-concevant les services numériques on réduit la puissance informatique (RAM, CPU, bande passante, nombre de serveurs, etc.) nécessaire au fonctionnement du service. La compréhension fine des besoins des usagers des services est également déterminante  pour adapter les fonctionnalités aux attentes réelles de ces derniers et ne pas alourdir les services de contenus et de fonctionnalités inutiles : le gras numérique. La pensée Design est donc fondamentale pour construire une architecture en juste équilibre avec les besoins des citoyens."
        },
        {
            questionID: 13,
            goodAnswers: [0, 1, 2],
            goodMessage: "Il faut avoir coché toutes les cases pour avoir bien répondu! En effet, ces différents impacts s’additionnent pour donner l’empreinte environnementale multicritère de l’usage quotidien du numérique.",
            wrongMessage: "Il fallait cocher toutes les cases pour avoir bien répondu! En effet, ces différents impacts s’additionnent pour donner l’empreinte environnementale multicritère de l’usage quotidien du numérique "
        },
        {
            questionID: 14,
            goodAnswers: [2],
            goodMessage: "Effectivement, le visionnage de vidéos sur un petit écran et via la wifi est l’usage le moins impactant pour l’environnement. Ainsi, le doublement de la diagonale moyenne des écrans  de 31 pouces à 65 pouces sur la période 2010-2025 contribue très significativement à l’augmentation des impacts de l’univers numérique. De plus, se connecter à internet en wifi avec son smartphone est moins énergivore qu’une connexion internet via le réseau 4G. La vidéo représente presque 60% de la demande du débit mondial.",
            wrongMessage: "Et non ! C’est le visionnage de vidéos sur un petit écran et via la wifi qui est l’usage le moins impactant pour l’environnement. Ainsi, le doublement de la diagonale moyenne des écrans de 31 pouces à 65 pouces sur la période 2010-2025 contribue très significativement à l’augmentation des impacts de l’univers numérique. De plus, se connecter à internet en wifi avec son smartphone est moins énergivore qu’une connexion internet via le réseau 4G. La vidéo représente presque 60% de la demande de débit mondial."
        },
        {
            questionID: 22,
            goodAnswers: [0, 1],
            goodMessage: "Bravo ! En effet, la consommation électrique ne permet pas d’évaluer l’impact environnemental puisque ce dernier dépend des modalités de production de l’électricité : Un kilowattheure d’électricité produit par un barrage hydroélectrique n’émet pas la même quantité de gaz à effet de serre qu’un kilowattheure d’électricité produit par une centrale à charbon. Au niveau mondial, les équipements utilisateurs contribuent effectivement quatre fois plus au réchauffement climatique que les centres de données. L’allongement de leur durée de vie est donc une action essentielle pour lutter contre l’empreinte environnementale du numérique !",
            wrongMessage: "Quel dommage ! Vous n’avez pas identifié les réponses erronées. Pour rappel, la consommation électrique ne permet pas d’évaluer l’impact environnemental puisque ce dernier dépend des modalités de production de l’électricité : Un kilowattheure d’électricité produit par un barrage hydroélectrique n’émet pas la même quantité de gaz à effet de serre qu’un kilowattheure d’électricité produit par une centrale à charbon. Au niveau mondial, les équipements utilisateurs contribuent effectivement quatre fois plus au réchauffement climatique que les centres de données. L’allongement de leur durée de vie est donc une action essentielle pour lutter contre l’empreinte environnementale du numérique !"
        }
    ]

    giveMeTheAnswer(answer, indexQ, indexA) {

        const goodAnswers = this.listGoodAnswers[indexQ].goodAnswers;

        if (answer.type === 'radio') {
            return (
                <Radio
                    id={`radio${answer.id}`}
                    checked={answer.checked ? true : false}
                >
                    <span
                        className="answerText"
                        style={{
                            color: goodAnswers.includes(indexA) ?
                                answer.checked ? 'green' : 'orange'
                                : null,
                            fontWeight: goodAnswers.includes(indexA) ? 'bold' : null
                        }}

                    >{answer.title}</span>
                </Radio>
            )
        } else if (answer.type === 'checkbox') {
            return (
                <Checkbox
                    id={`check${answer.id}`}
                    checked={answer.checked ? true : false}
                >
                    <span
                        className="answerText"
                        style={{
                            color: goodAnswers.includes(indexA) ?
                                answer.checked ? 'green' : 'orange'
                                : null,
                            fontWeight: goodAnswers.includes(indexA) ? 'bold' : null
                        }}
                    >{answer.title}</span>
                </Checkbox>
            )
        }
    }

    giveMeTheMessage(question, indexQ) {

        let result = null;
        const theQuestion = this.listGoodAnswers[indexQ];

        question.answers.forEach((answer, indexA) => {
            if (theQuestion.goodAnswers.includes(indexA)) {
                if (answer.checked) {
                    result = theQuestion.goodMessage;
                } else {
                    result = theQuestion.wrongMessage;
                }
            }
        })

        return result;
    }

    render() {
        return (
            this.props.ListQuestions.map((question, indexQ) => {
                return (
                    <div className="questionContainer" key={`info${indexQ}`}>
                        <div className="questionHeader">
                            <Icon icon="comments" size="2x" />
                            <span className="question"
                                dangerouslySetInnerHTML={{ __html: question.title }}
                            >
                            </span>
                        </div>
                        {question.answers.map((answer, indexA) => {
                            return (
                                <Row className="show-grid answerContent" key={answer.id}>
                                    <Col xs={24} className="answerSubContent">
                                        {this.giveMeTheAnswer(answer, indexQ, indexA)}
                                    </Col>
                                </Row>
                            );
                        })
                        }
                        <MessageInformez message={this.giveMeTheMessage(question, indexQ)} />
                    </div>


                )
            })


        );
    }
}

export default ResultsInformez;
