import React, { Component } from 'react';
import { InputNumber } from 'rsuite';

class IntInp extends Component {
    //Transmission props components parents
    onData(value) {
        this.props.onData(value);
    }

    componentDidMount() {
        if (this.props.answer.value) {
            document.getElementById(`input${this.props.answer.id}`).value = this.props.answer.value;
        }
    }

    render() {
        const { answer } = this.props;
        return (
            <div className="answerInput">
                <InputNumber
                    id={`input${answer.id}`}
                    placeholder={answer.value && answer.checked ? answer.value : answer.placeholder}
                    disabled={answer.checked || answer.type === 'simple' ? false : true}
                    onChange={this.onData.bind(this)}
                    className="inputNumber"
                    min={0}
                />
            </div>
        );
    }

};

export default IntInp;
