import React from "react";
import { FlexboxGrid } from 'rsuite';

const Message = (question) => {
  return (
    <FlexboxGrid justify="center" align="middle" className="questionContainer" >
      <FlexboxGrid.Item colspan={24} className="byeMessageContainer">
      <div 
        className="messageText" 
        dangerouslySetInnerHTML={{__html: question.question.message.text}} 
        style={{textAlign: question.question.message.align}}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Message;