import React from 'react';
import {  Icon, Whisper, Tooltip } from 'rsuite';

const Simple = ({ answer, demo }) => {
    return (
        <div className="simpleContainer" key={answer.id}>
            <span className="answerText"> {answer.title}</span>
            {answer.message ?
                        <Whisper
                            trigger="hover"
                            placement="right"
                            speaker={
                                <Tooltip>
                                    {answer.message}
                                </Tooltip>
                            }
                        >
                            <Icon icon="question-circle2" size="lg" className="helperIcon" />
                        </Whisper>
                        : null}
            {demo ? <span className="demo">{`(A${answer.id})`}</span> : null}
        </div>
    )
};

export default Simple;
