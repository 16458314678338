import React, { Component } from 'react';
import Stars from '../components/stars';
import { Divider, Row, Col } from 'rsuite';

class Vote extends Component {
    onAction(data) {
        this.props.onAction(data);
    }

    render() {
        const { answer } = this.props;
        return (
            <>
            <Row className="show-grid answerContent">
                <Col xs={24} sm={17} className="col valign-wrapper answerText answerSubContent">
                    {answer.title}
                    {this.props.demo ? <span className="demo">{`(A${answer.id})`}</span> : null}
                </Col>
                <Col xs={24} sm={7} className="answerSubContent">
                    <Stars score={answer.value ? answer.value : 0} answerID={answer.rank} onAction={this.onAction.bind(this)} />
                </Col>
            </Row>
            <Row className="show-grid">
                <Divider />
            </Row>
            </>
        );
    }
}

export default Vote;
