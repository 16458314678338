import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel, HelpBlock } from 'rsuite';

class UserTextInp extends Component {

    onBlur(event) {
        this.props.onBlur(event.target.id, event.target.value);
    }

    onCheckInput(value, event) {
        if (event.target.id === 'email') {
            this.props.onCheckInput(value);
        }
    }


    render() {
        const { element } = this.props;
        
        return (
            <FormGroup className="answerUserInput">
                <ControlLabel>{element.title}</ControlLabel>
                <FormControl
                    name={element.title}
                    id={element.id}
                    onBlur={this.onBlur.bind(this)}
                    onChange={this.onCheckInput.bind(this)}
                />
                {element.id === 'email' && this.props.disabledBut !== 2 ?
                    this.props.disabledBut === 0 ?
                        <HelpBlock>Requis</HelpBlock>
                        : <HelpBlock style={{ color: 'red' }}>Adresse invalide</HelpBlock>
                    : null}
            </FormGroup>
        );
    }

};

export default UserTextInp;
