import React, { Component } from 'react';
import { Icon, Whisper, Tooltip, FlexboxGrid } from 'rsuite';
import Answer from './answer';
import Rank from '../containers/rank';
import RankResult from './rankResult';
import QuestionTrigger from '../containers/questionTrigger';
import MessageInformez from './messageInformez';

class Question extends Component {

    upTheBlocks(answerUP) {

        let questionUp = this.props.question;

        //Update des bouttons radio
        if (answerUP.type === 'radio') {
			questionUp.answers.forEach((answer, indexA) => {
				questionUp.answers[indexA].checked = indexA === answerUP.rank ? true : false;
            });
        }

        //Update de la réponse
        questionUp.answers[answerUP.rank] = answerUP;

        this.props.upTheBlocks(questionUp, answerUP);
    }

    onRank(listRank) {
        this.props.onRank(listRank);
    }

    theGoodAnswers(userAnswers) {
        const theGoodOrder = [59, 58, 63, 60, 62, 61, 66, 64, 65, 67];
        const theRightList = [];

        theGoodOrder.forEach((position, indexGood) => {
            userAnswers.forEach((answer, indexUser) => {
                if (answer.id === position) {
                    let newAnswer = {
                        answer,
                        result: indexGood === indexUser
                    };
                    theRightList.push(newAnswer);
                }
            });
        });

        return theRightList;
    }

    render() {
        return (
            <div className="questionContainer">
                <div className="questionHeader">
                    <Icon icon="comments" size="2x" />
                    <span className="question"
                    dangerouslySetInnerHTML={{__html: this.props.question.title}}
                    >
                    </span>
                    {this.props.question.message ?
                        <Whisper
                            trigger="hover"
                            placement="right"
                            speaker={
                                <Tooltip>
                                    {this.props.question.message.text}
                                </Tooltip>
                            }
                        >
                            <Icon icon="question-circle2" size="lg" className="helperIcon" />
                        </Whisper>
                        : null}
                    {this.props.demo ? <span className="demo">{`(Q${this.props.question.id})`}</span> : null}
                </div>
                <div className="questionContent">
                    {this.props.question.type === "rank" ?
                        //Affichage réponses Q15
                        this.props.index === 7 ?
                            <div>
                                <MessageInformez message={"La suppression des emails stockés et l’utilisation de moteurs de recherche « vert » participent de croyances répandues mais ne constituent pas de sérieuses actions pour diminuer l’impact environnemental du numérique."} />
                                <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={12}>
                                        <div className="halfContainer">
                                            <h6>Vos réponses</h6>
                                        </div>
                                        <RankResult list={this.props.question.answers} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <div className="halfContainer">
                                            <h6>Bonnes réponses</h6>
                                        </div>
                                        <RankResult list={this.theGoodAnswers(this.props.question.answers)} />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                            :
                            <div>
                                <div className="halfContainer">
                                    <h6>Le plus important</h6>
                                </div>
                                <Rank answers={this.props.question.answers} onRank={this.onRank.bind(this)} demo={this.props.demo} />
                                <div className="halfContainer">
                                    <h6>Le moins important</h6>
                                </div>
                            </div>
                        :
                        this.props.question.type === "trigger" ?
                        //Affichage réponses Q16 à 18
                    <QuestionTrigger 
                        question={this.props.question} 
                        upTheBlocks={this.upTheBlocks.bind(this)}
                        demo={this.props.demo}
                    />
                        :
                        this.props.question.answers.map(answer => {
                            return (
                                <Answer
                                    answer={answer}
                                    questionRank={this.props.question.rank}
                                    upTheBlocks={this.upTheBlocks.bind(this)}
                                    key={answer.id}
                                    demo={this.props.demo}
                                />
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Question;


