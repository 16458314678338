/* 
* Requetes de récupération des blocs de questions / réponses + persistance des résultats de l'utilisateur
*/

import Axios from 'axios';

const BASE_URL = 'https://greenitapi.espedata.fr';

export function fetchBlocks() {
  return new Promise((resolve, reject) => {
    Axios.get(`${BASE_URL}/fetch.php`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  })
}

export function saveToDB(datas) {
  return new Promise((resolve, reject) => {
    Axios.post(`${BASE_URL}/add.php`, {
      datas
    }
    )
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error.message);
      });
  })
}
