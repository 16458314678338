import React, { Component } from 'react';

class RankResult extends Component {

    render() {
        return (
            <div>
                {this.props.list.map(element => {
                    return (
                        element.answer ?
                        <div 
                            className="rankItemContainer"
                            key={`userA${element.answer.id}`}
                            style={{
                                width: '90%',
                                border: `2px solid ${element.result ? '#21830d' : '#f37611'}`
                                }}
                            >
                            {element.answer.title}
                        </div>
                        :
                        <div className="rankItemContainer" key={`goodA${element.id}`} style={{width: '90%'}}>
                        {element.title}
                    </div>
                    )
                })}
            </div>
        );
    }
}
export default RankResult;
