import React, { Component } from 'react';
import { fetchBlocks, saveToDB } from '../tools/api';
import Main from './main';
import routes from '../tools/router';

//RSuite
import 'rsuite/dist/styles/rsuite-default.css';
import { Container, Content, Grid, Row, Toggle, Col } from 'rsuite';

require("../style.css");

class App extends Component {
	constructor() {
		super();
		this.state = {
			blocks: [],
			user: [
				{
					id: 'name',
					title: 'Nom',
					value: ''
				},
				{
					id: 'firstName',
					title: 'Prénom',
					value: ''
				},
				{
					id: 'coll',
					title: 'Collectivité',
					value: ''
				},
				{
					id: 'email',
					title: 'Courriel',
					value: ''
				}
			],
			onRankList: [],
			disabledBut: 0, // 0: Vide / 1: en cours non validé / 2: Validé
			position: {
				index: 0,
				extrem: 'start'
			},
			demo: false,	//Activation de l'affichage des numéros de questions et réponses
			nextDisabled: false	//Desactivation du bouton Suivant
		};
	}

	componentDidMount() {
		fetchBlocks()
			.then(datas => {
				this.setState({ blocks: datas })
			});
	}

	gimmeTheBlock(index) {
		const newElement = routes[index];

		let newKidsOnTheBlock = {
			index,
			id: newElement.id,
			type: newElement.component,
			datas: null,
			title: "",
			progression: newElement.progression
		};

		let i = 0;

		if (newElement.component === 'question' || newElement.component === 'message') {
			while (!newKidsOnTheBlock.datas && i < this.state.blocks.length) {
				const block = this.state.blocks[i];

				if (newElement.id === block.id) {
					newKidsOnTheBlock.datas = block;
					newKidsOnTheBlock.title = block.title;
				}
				i++;
			}

		} else if (newElement.component === 'resultats') {
			let listIDs = [6, 7, 8];
			newKidsOnTheBlock.datas = [];
			newKidsOnTheBlock.title = "Vos impacts";

			while (newKidsOnTheBlock.datas.length <= 3 && i < this.state.blocks.length) {
				const block = this.state.blocks[i];

				if (listIDs.includes(block.id)) {
					newKidsOnTheBlock.datas.push(block);
				}
				i++;
			}
		} else if (newElement.component === 'informezResults') {

			while (!newKidsOnTheBlock.datas && i < this.state.blocks.length) {
				const block = this.state.blocks[i];

				if (newElement.id === block.id) {
					newKidsOnTheBlock.title = 'Informez-vous: vos réponses';
					newKidsOnTheBlock.datas = this.state.blocks[i].questions;
				}
				i++;
			}
		} else if (newElement.component === 'introduction') {
			newKidsOnTheBlock.title = "Sobriété numérique et collectivités territoriales : quels enjeux ?";
		}

		return newKidsOnTheBlock;
	}

	//Methode générique de mise à jour de la liste des questions / réponses de l'utilisateur
	upTheBlocks(blockUp) {

		let newBlocks = this.state.blocks;

		newBlocks.forEach((block, indexB) => {

			if (block.id === blockUp.id) {
				newBlocks[indexB] = blockUp;
			}
		})
		this.setState({ blocks: newBlocks });
	}

	onUserBlur(inputID, inputValue) {

		const newUser = this.state.user;

		newUser.forEach(element => {
			if (element.id === inputID) {
				element.value = inputValue;
			}
		})

		this.setState({ user: newUser })
	}

	//Activation du boutton Envoyer
	onCheckInput(value) {
		//regex
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		// 0: Vide / 1: en cours non validé / 2: Validé
		if (value.length === 0) {
			this.setState({ disabledBut: 0 });
		} else if (re.test(value)) {
			this.setState({ disabledBut: 2 });
		} else {
			this.setState({ disabledBut: 1 });
		}

	}

	onRank(listRank) {
		this.setState({ onRankList: listRank });
	}

	onNext() {
		let newIndex = this.state.position.index + 1;

		this.setState({
			position: {
				index: newIndex,
				extrem: this.gimmePos(newIndex)
			}
		});
	}

	onPrevious() {
		let newIndex = this.state.position.index - 1;

		this.setState({
			position: {
				index: newIndex,
				extrem: this.gimmePos(newIndex)
			}
		});
	}

	onModeDemo() {
		this.setState({ demo: !this.state.demo });
	}

	onTrigger(ID) {
		document.getElementById(`input${ID}`).disabled = !document.getElementById(`input${ID}`).disabled;
	}


	gimmePos(nextIndex) {
		if (nextIndex < 1) {
			return 'start';
		} else if (nextIndex === routes.length - 2) {
			return 'user';
		} else if (nextIndex >= routes.length - 1) {
			return 'end';
		}
		return null;
	}

	sendDatas() {
		//Recuperation des informations User
		const { user } = this.state;

		const objectToSend = {
			"service": {
				"collectivite": user[2].value,
				"nom": user[0].value,
				"prenom": user[1].value,
				"email": user[3].value
			},
			"answers": []
		}

		//Récupération des réponses
		this.state.blocks.forEach(block => {
			block.questions.forEach(question => {
				//Récupération du classement de la question "Les principales actions à mettre en oeuvre sont"
				if (question.type === "rank") {
					//Pas de modif de la liste par User
					if (this.state.onRankList.length === 0) {
						question.answers.forEach(answer => {
							objectToSend.answers.push({
								"question_id": question.id,
								"answer_id": answer.id,
								"data": answer.rank,
								"default_answer": false
							});
						})
					} else {
						this.state.onRankList.forEach((answer, index) => {
							objectToSend.answers.push({
								"question_id": question.id,
								"answer_id": answer.id,
								"data": index,
								"default_answer": false
							});
						})
					}

				} else if (question.type === "trigger") {
					//Réponses par défault si non répondue
					question.answers.forEach(answer => {
						if (!answer.checked) {
							objectToSend.answers.push({
								"question_id": question.id,
								"answer_id": answer.id,
								"data": (answer.id === 69 || answer.id === 73 || answer.id === 77) ? 4
									: (answer.id === 68 || answer.id === 72 || answer.id === 76) ? 1 : 0,
								"default_answer": true
							});
						} else {
							objectToSend.answers.push({
								"question_id": question.id,
								"answer_id": answer.id,
								"data": answer.value,
								"default_answer": false
							});
						}
					})
				}
				else {
					//Récupération des autres réponses
					question.answers.forEach(answer => {
						if (answer.checked) {
							objectToSend.answers.push({
								"question_id": question.id,
								"answer_id": answer.id,
								"data": answer.value,
								"default_answer": false
							});
						}
					})
				}
			})

		})
		this.onNext();

		saveToDB(objectToSend)
			.then()
			.catch(error => {
				console.error(error);
			});
	}

	render() {
		const theElement = this.state.blocks.length > 0 ? this.gimmeTheBlock(this.state.position.index) : null;

		return (
			<Container>
				<Content>
					<Grid fluid>
						<Row className="show-grid mainContainer">
							{/* Demo Mode */}
							<Col xs={24} md={16} mdOffset={4} className="demoContainer">
								<Toggle
									size="md"
									checkedChildren="Démo activée"
									unCheckedChildren="Démo désactivée"
									onChange={this.onModeDemo.bind(this)}
								/>
							</Col>
							{/* Main Container */}
							{theElement ?
								<Main
									element={theElement}
									user={this.state.user}
									position={this.state.position.extrem}
									disabledBut={this.state.disabledBut}
									upTheBlocks={this.upTheBlocks.bind(this)}
									onRank={this.onRank.bind(this)}
									onNext={this.onNext.bind(this)}
									onPrevious={this.onPrevious.bind(this)}
									onUserBlur={this.onUserBlur.bind(this)}
									onCheckInput={this.onCheckInput.bind(this)}
									onSendDatas={this.sendDatas.bind(this)}
									onTrigger={this.onTrigger.bind(this)}
									demo={this.state.demo}
								/>
								: null
							}
						</Row>
					</Grid>
				</Content>
			</Container>
		);
	}
}

export default App;
