import React, { Component } from 'react';
import UserTextInp from './userTextInput';
import { FlexboxGrid, Row, Col, Form, Button, Icon } from 'rsuite';

class UserBlock extends Component {

    onUserBlur(inputID, inputValue) {
        console.log("input");
        this.props.onUserBlur(inputID, inputValue);
    }

    onCheckInput(value) {
        this.props.onCheckInput(value);
    }

    onSendDatas() {
        console.log("go");
        this.props.onSendDatas();
    }

    render() {

        return (
            <>
                <FlexboxGrid.Item colspan={24} className="blockInfo">
                    <p>En soumettant ce formulaire, j’accepte que les informations saisies soient exploitées exclusivement
                par Espelia dans le cadre de la démarche présente. <br />Les informations personnelles recueillies sur ce
                            formulaire sont enregistrées dans un fichier informatisé par Espelia et ne seront en aucun cas communiquées
                             à un tiers.  Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement à
                tout moment.<br />
                        Consultez le site cnil.fr pour plus d’informations sur vos droits.</p>
                </FlexboxGrid.Item>
                <Row className="show-grid questionContainer">
                    <Form>
                        {this.props.user.map(element => {
                            return (
                                <Col xs={24} sm={12} className="answerSubContent" key={element.id}>
                                    <UserTextInp
                                        element={element}
                                        onBlur={this.onUserBlur.bind(this)}
                                        onCheckInput={this.onCheckInput.bind(this)}
                                        disabledBut={this.props.disabledBut}
                                    />
                                </Col>
                            )
                        })}
                    </Form>
                </Row>
                {/* Validation */}
                <FlexboxGrid.Item colspan={6} className="envoyerButtContainer">
                    <Button
                        appearance="primary"
                        className="btnNavigation"
                        size="lg"
                        onClick={this.onSendDatas.bind(this)}
                        disabled={this.props.disabledBut !== 2 && !this.props.demo}
                    >
                        <Icon icon="send" />  Envoyer</Button>
                </FlexboxGrid.Item>
            </>
        );
    }
}
export default UserBlock;
