import React, { Component } from 'react';
import { Radio, Whisper, Popover } from 'rsuite';

const Speaker = ({ message, ...props }) => {
    return (
        <div className="messageAnswerContainer">
            <Popover title="Information" {...props}>
                <p className="messageText">{message}</p>
            </Popover>
        </div>
    );
};

class RadioA extends Component {
    onAction() {
        this.props.onAction(null);
    }

    gimmeTheRadio(answer) {
        if(answer.message) {
            return (
                <Whisper
                    placement="bottomStart"
                    trigger="active"
                    speaker={<Speaker message={answer.message}
                />}
                >
                    <Radio
                        id={`radio${answer.id}`}
                        checked={answer.checked ? true : false}
                        onChange={this.onAction.bind(this)}
                    >
                        <span className="answerText">{answer.title}</span>
                        {this.props.demo ? <span className="demo">{`(A${answer.id})`}</span> : null}
                    </Radio>
                </Whisper>
            )
        } 
        
        return (
            <Radio
            id={`radio${answer.id}`}
            checked={answer.checked ? true : false}
            onChange={this.onAction.bind(this)}
        >
            <span className="answerText">{answer.title}</span>
            {this.props.demo ? <span className="demo">{`(A${answer.id})`}</span> : null}
        </Radio>
        )
    }

    render() {
        return this.gimmeTheRadio(this.props.answer);
    }
}

    export default RadioA;
