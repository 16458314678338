import React, { Component } from 'react';
import { Row, FlexboxGrid } from 'rsuite';
import infographie1 from '../medias/Infographie1.jpg';
import infographie2 from '../medias/Infographie2.jpg';
import espeliaLogo from '../medias/logo_espelia.png';
import greenitLogo from '../medias/greenit_logo.png';

class Introduction extends Component {

    render() {
        return (
            <>
            <FlexboxGrid.Item colspan={24} className="blockHead">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item colspan={4}>
                        <img src={espeliaLogo} alt="LogoEspelia" className="blockImageIntro" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={16}>
                        <h5 className="blockTitleIntro">Sobriété numérique et collectivités territoriales : quels enjeux ?</h5>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                        <img src={greenitLogo} alt="GreenITLogo" className="blockImageIntro" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FlexboxGrid.Item>
            <Row className="show-grid questionContainer">
                <div className="introImageContainer">
                    <img src={infographie1} alt="infographie1" className="introImage" />
                    <img src={infographie2} alt="infographie2" className="introImage" />
                </div>
                <div className="messageContainer" style={{ textAlign: 'justify' }}>
                    <div className="messageText">
                        <p>Transitions écologique et numérique sont intimement liées et doivent être considérées conjointement pour atteindre les
                            objectifs fixés pour limiter le réchauffement climatique. Les collectivités territoriales constituent un levier important dans la diminution
                             de l’empreinte environnementale du numérique à la fois par leur mission de développement territorial, leur devoir d’exemplarité et leur
                             influence au travers de la commande publique.<br /><br />
                             <p>
                             La présente enquête constitue la première étape d’une démarche globale qui interrogera les différents leviers d’actions des collectivités pour diminuer l’impact environnemental :
                             <ul>
                                 <li>De leur parc d’équipements numériques</li>
                                 <li>De leurs services numériques (internes et externes) </li>
                                 <li>Des usages des habitants et acteurs de leur territoire à l’égard de leur consommation de services et d’équipements numériques </li>
                             </ul>
                             </p>
                             En participant à cette enquête <b>(10 min)</b> vous pourrez monter en compétence et serez en capacité de <b>décrypter les principaux enjeux de la
                            sobriété numérique</b> grâce aux chiffres clés de la problématique. Vous pourrez également calculer l’impact environnemental de votre parc numérique
                             et son <b>potentiel d’amélioration</b> en vous confrontant aux résultats des meilleures pratiques. <br /><br />
                            <p>
                                Dans le cas où vous souhaiteriez connaitre de façon précise l’impact environnemental de votre parc numérique, vous pouvez dès à présent
                                vous munir des informations concernant le nombre et la durée d’utilisation de vos écrans, ordinateurs et laptops dans votre collectivité,
                             leur taux de réemploi et d’éco-labellisation.<br /><br />
                                Si vous ne disposez pas de ces informations pas de souci ! Vous pourrez tout de même bénéficier d’une évaluation en indiquant approximativement
                            le nombre d’équipements en votre possession.</p><br />
                            Les chiffres présentés dans cette enquête sont issus des travaux menés par Frédéric Bordage dans le cadre des
                             <a href="https://www.greenit.fr/empreinte-environnementale-du-numerique-mondial/" target="_blank" rel="noopener noreferrer"> études</a> qu’il a publiées avec le collectif GreenIT.fr.<br /><br /></p>
                    </div>
                </div>
            </Row>
            </>
        );
    }
}
export default Introduction;
