import React, { Component } from 'react';

class RankItem extends Component {
    render() {
        return (
            <div className="rankItemContainer">
                {this.props.value}
                {this.props.demo ? <span className="demo">{`(A${this.props.id})`}</span> : null}
            </div>
    );
    }
}
export default RankItem;
