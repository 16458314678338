import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import RankItem from '../components/rankItem';

const SortableItem = SortableElement(({ value, id, demo }) => <RankItem value={value} id={id} demo={demo}/>);

const SortableList = SortableContainer(({ answers, demo }) => {
  return (
    <div>
      {answers.map((answer, index) => (
        <SortableItem key={`item-${answer.id}`} index={index} value={answer.title} id={answer.id} demo={demo} />
      ))}
    </div>
  );
});

function move(arr, old_index, new_index) {
  new_index = ((new_index % arr.length) + arr.length) % arr.length;
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

class Rank extends Component {
  state = {
    answers: [],
  };

  onRank(listRank) {
    this.props.onRank(listRank);
  }

  componentDidMount() {
    this.setState({ answers: this.props.answers });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = move(this.state.answers, oldIndex, newIndex);
    this.setState({ answers: newArray });
    this.onRank(newArray);
  };
  render() {
    return <SortableList answers={this.state.answers} onSortEnd={this.onSortEnd} demo={this.props.demo}/>;
  }
}
export default Rank;
