import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import Answer from "../components/answer";
import AnswerTrigger from "../components/answerTrigger";

class QuestionTrigger extends Component {

	constructor (props) {
		super(props);
		this.state = {
			listTriggers: []
		};
	}

	upTheBlocks(answerUP) {
        this.props.upTheBlocks(answerUP);
	}

	componentDidMount() {
		//Update des triggers
		let newList = [];
		const theAnswers = this.props.question.answers;

		for(let i = 1 ; i < theAnswers.length ; i++) {
			if (theAnswers[i].value) {
				newList.push(true);
			} else {
				newList.push(false);
			}
		}

		this.setState({listTriggers: newList});
	}
	
	//Inversion du booleen Trigger concerné
	onTrigger(answerIndex) {
		let newList = this.state.listTriggers;
		newList[answerIndex] = !this.state.listTriggers[answerIndex];
		this.setState({listTriggers: newList});
	}

	render () {
		const answerOne = this.props.question.answers[0],
			theOtherOnes = [];

		this.props.question.answers.forEach((answer, index) => {
			if (index !== 0) {
				theOtherOnes.push(answer);
			}
		});

		return (
			<div className="questionContent">
				<FlexboxGrid>
					<FlexboxGrid.Item colspan={12}>
						{/* Nombre d'unités */}
						<Answer
							answer={answerOne}
							questionRank={this.props.question.rank}
							upTheBlocks={this.upTheBlocks.bind(this)}
							key={answerOne.id}
							demo={this.props.demo}
						/>
					</FlexboxGrid.Item>
				</FlexboxGrid>
				{/* Autres réponses activables */}
				{theOtherOnes.map((answer, index) => {
					return (
						<FlexboxGrid align="middle" key={`trigger${answer.id}`}>
							<AnswerTrigger
								answer={answer}
								upTheBlocks={this.upTheBlocks.bind(this)}
								onTrigger={this.onTrigger.bind(this, index)}
								key={answer.id}
								demo={this.props.demo}
								trigger={this.state.listTriggers[index]}
							/>
						</FlexboxGrid>
					);
				})
				}

			</div>
		);
	}
}

export default QuestionTrigger;
