import React, { Component } from 'react';
import { Col, Button, Icon, FlexboxGrid, Row, Progress } from 'rsuite';
import Question from '../components/question';
import Message from '../components/message';
import UserBlock from '../components/userBlock';
import Introduction from '../components/introduction';
import Results from '../components/results';
import ResultsInformez from '../components/resultsInformez';
import espeliaLogo from '../medias/logo_espelia.png';
import greenitLogo from '../medias/greenit_logo.png';

class Main extends Component {

    constructor(props) {
        super(props)
        this.state = {
            height: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        this.setState({ height: window.innerHeight })
    }

    upTheBlocks(questionUp, answerUP) {

        let blockUp = this.props.element.datas;

        blockUp.questions.forEach((blockQuestion, indexQ) => {
            if (blockQuestion.id === questionUp.id) { //Update de la question en cours
                blockUp.questions[indexQ] = questionUp;

            } else {//Affichage des questions cachées
                questionUp.answers.forEach(answer => {
                    if (answer.link.includes(blockQuestion.id)) {
                        blockUp.questions[indexQ].active = answer.id === answerUP.id ? true : false;
                    }
                })
            }
        });

        this.props.upTheBlocks(blockUp);
    }




    // onClick(question, answerID, data) {
    //     this.props.onClick(question, answerID, data);
    // }

    // onBlur(question, answerID, data) {
    //     this.props.onBlur(question, answerID, data);
    // }

    onRank(listRank) {
        this.props.onRank(listRank);
    }

    onUserBlur(inputID, inputValue) {
        this.props.onUserBlur(inputID, inputValue);
    }

    onCheckInput(value) {
        this.props.onCheckInput(value);
    }

    onNext() {
        this.props.onNext();
    }

    onPrevious() {
        this.props.onPrevious();
    }

    onSendDatas() {
        this.props.onSendDatas();
    }

    onTrigger(ID) {
        this.props.onTrigger(ID);
    }

    nextOrNot(thatElement) {
        let listBool = [];

        if (thatElement.datas && thatElement.type !== 'resultats' && thatElement.type !== 'informezResults') {
            thatElement.datas.questions.forEach(question => {
                let response = true;

                if (question.required) {
                    response = false;
                    question.answers.forEach(answer => {
                        if (answer.checked) {
                            response = true;
                        }
                    })
                }

                listBool.push(response);
            })
        }

        return listBool.includes(false);
    }

    render() {
        const theElement = this.props.element;
        const { position } = this.props;
        const { Line } = Progress;

        return (
            <Col xs={24} md={16} mdOffset={4} className="main" style={{ minHeight: this.state.height - 70 }}>
                <FlexboxGrid align="top" justify="center">
                {theElement.type !== "introduction" ?
                    <FlexboxGrid.Item colspan={24} className="blockHead">
                        <FlexboxGrid align="middle" justify="space-around">
                            <FlexboxGrid.Item colspan={6}>
                                <img src={espeliaLogo} alt="LogoEspelia" className="blockImage" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={12}>
                                <h5 className="blockTitle">{theElement.title}</h5>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <img src={greenitLogo} alt="GreenITLogo" className="blockImage" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        {/* Barre de progression */}
                        {theElement.progression !== null ?
                            <FlexboxGrid align="middle" justify="center" className="progressBarContainer">
                                <FlexboxGrid.Item colspan={24}>
                                    <Line percent={theElement.progression} strokeColor="#98D098" />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            : null}
                    </FlexboxGrid.Item>
                    : null}
                    {theElement.type === "question" ?
                        <>
                            {theElement.datas.info ?
                                <FlexboxGrid.Item colspan={24} className="blockInfo">{theElement.datas.info}</FlexboxGrid.Item>
                                : null}
                            {/* Affichage message ou questions */}
                            {theElement.datas.questions.map(question => {
                                return question.active
                                    ? <Question
                                        index={theElement.index}
                                        question={question}
                                        key={question.id}
                                        upTheBlocks={this.upTheBlocks.bind(this)}
                                        onRank={this.onRank.bind(this)}
                                        onTrigger={this.onTrigger.bind(this)}
                                        demo={this.props.demo}
                                    />
                                    : null;
                            })
                            }
                        </>
                        : theElement.type === 'message' ?
                            <Message question={theElement.datas.questions[0]} />
                            : theElement.type === "user" ?
                                <UserBlock
                                    user={this.props.user}
                                    onUserBlur={this.onUserBlur.bind(this)}
                                    onCheckInput={this.onCheckInput.bind(this)}
                                    onSendDatas={this.onSendDatas.bind(this)}
                                    disabledBut={this.props.disabledBut}
                                    demo={this.props.demo}
                                />
                                : theElement.type === "introduction" ?
                                    <Introduction />
                                    : theElement.type === "resultats" ?
                                        <Results listBlocs={theElement.datas} />
                                        : theElement.type === "informezResults" ?
                                            <ResultsInformez ListQuestions={theElement.datas} />
                                            : null
                    }
                    {/* Bar de bouttons next / previous */}
                    <Row className="rowButt">
                        {position !== 'start' && position !== 'end' ?
                            <Col xs={position !== 'user' ? 12 : 24} className="buttonLeft">
                                <Button
                                    appearance="primary"
                                    className="btnNavigation"
                                    size="md"
                                    onClick={() => this.onPrevious()}
                                >
                                    <Icon icon="left" /> Retour</Button>
                            </Col>
                            : null}
                        {position !== 'user' && position !== 'end' ?
                            <Col xs={position !== 'start' ? 12 : 24} 
                                className={theElement.type === "introduction" ? "buttonCenter" : "buttonRight"}>
                                <Button
                                    appearance="primary"
                                    className= {theElement.type === "introduction" ? "btnNavigationBIG" : "btnNavigation"}
                                    size={theElement.type === "introduction" ? "lg": "md"}
                                    onClick={() => this.onNext()}
                                    disabled={this.nextOrNot(theElement) && !this.props.demo}
                                >
                                    {theElement.type === "introduction" ? "Démarrer" : "Suivant"} <Icon icon="right" /></Button>
                            </Col>
                            : null}
                    </Row>
                </FlexboxGrid>
            </Col>
        );
    };
}

export default Main;