import React, { Component } from 'react';
import { Row } from 'rsuite';
import ResultElement from './resultElement';

class Results extends Component {

    calcul(datas) {
        //Constantes
        let constantes = {
            'Ordinateurs portables': {
                GES: {
                    star: 31,
                    fab: 255,
                    use: 2
                },
                MP: {
                    star: 35,
                    fab: 125,
                    use: 18
                }
            },
            'Ecrans': {
                GES: {
                    star: 45,
                    fab: 399,
                    use: 6
                },
                MP: {
                    star: 281,
                    fab: 1944,
                    use: 100
                }
            },
            'Ordinateurs de bureau': {
                GES: {
                    star: 28,
                    fab: 214,
                    use: 7
                },
                MP: {
                    star: 144,
                    fab: 800,
                    use: 80
                }
            }
        }

        //Récupération des résultats
        let listResults = [];

        datas.forEach(element => {
            let question = element.questions[0];
            let newResult = {
                title: question.title,
                unites: question.answers[0].value ? parseInt(question.answers[0].value) : 1,
                dureeVie: question.answers[1].value ? parseInt(question.answers[1].value) : 4,
                reemploi: question.answers[2].value ? parseFloat(question.answers[2].value / 100) : 0,
                ecolabels: question.answers[3].value ? parseFloat(question.answers[3].value / 100) : 0,
            }

            let fabGES = constantes[question.title].GES.fab;
            let fabMP = constantes[question.title].MP.fab;
            let useGES = constantes[question.title].GES.use;
            let useMP = constantes[question.title].MP.use;
            let starGES = constantes[question.title].GES.star;
            let starMP = constantes[question.title].MP.star;
            
            //Calculs GES
            newResult.GESimpact = ((((fabGES / (newResult.dureeVie + ((newResult.dureeVie * newResult.reemploi)) * 0.75)) + useGES)) + (((fabGES / (newResult.dureeVie + ((newResult.dureeVie * newResult.reemploi)) * 0.75)) + useGES)) * (0.10 * (1 - newResult.ecolabels)))*newResult.unites;
            newResult.GESecart = ((newResult.GESimpact - starGES) / newResult.GESimpact) * 100;

            //Calculs EP
            newResult.MPimpact = (((fabMP / (newResult.dureeVie + ((newResult.dureeVie * newResult.reemploi)) * 0.75)) + useMP)) + (((fabMP / (newResult.dureeVie + ((newResult.dureeVie * newResult.reemploi)) * 0.75)) + useMP)) * (0.10 * (1 - newResult.ecolabels));
            newResult.MPecart = ((newResult.MPimpact - starMP) / newResult.MPimpact) * 100;
            
            //Traitement resultats pour affichage
            newResult.GESecart = isNaN(newResult.GESecart) || !isFinite(newResult.GESecart) ? 'N/A' : Math.round(newResult.GESecart);
            newResult.GESimpact = isNaN(newResult.GESimpact) || !isFinite(newResult.GESimpact) ? 'N/A' : Math.round(newResult.GESimpact);
            newResult.MPecart = isNaN(newResult.MPecart) || !isFinite(newResult.MPecart) ? 'N/A' : Math.round(newResult.MPecart);
            newResult.MPimpact = isNaN(newResult.MPimpact) || !isFinite(newResult.MPimpact) ? 'N/A' : Math.round(newResult.MPimpact);

            listResults.push(newResult);
        });

        return listResults;
    }
    render() {
        const theDatas = this.calcul(this.props.listBlocs);

        return (
            <>
            <div className="blockInfo">
                <p>
                Cette évaluation ne reflète que l’impact environnemental en matière d’émission de gaz à effet de serre et d’énergie primaire. Une analyse précise et 
                cohérente de l’empreinte environnementale du numérique demanderait une approche systémique et multicritère pour laquelle les GES et l’énergie primaire 
                ne constituent que quelques-uns des différents indicateurs à prendre en compte.
                </p><br />
                <p>
                De même, ces résultats ne traduisent pas l’ensemble de l’empreinte numérique de vos activités puisqu’ils ne découlent pas d’un raisonnement en termes de « service numérique ». <b>Pour ce faire, il faudrait prendre en compte les impacts de tous les équipements physiques sous-jacents</b> (terminaux, réseau, serveurs, antennes relais, etc.) participant à délivrer un résultat, par exemple « payer la cantine en ligne ».
                </p><br />
                <p>Les potentiels d’amélioration indiqués permettent de vous comparer aux meilleures pratiques du secteur privé et de mesurer les marges de progression possibles</p><br />
                <p>A titre indicatif, la combustion d’un litre d’essence émet 2,3 kg eq.CO2</p>
            </div>
            <Row className="show-grid questionContainer">
                {theDatas.map((element, index) => {
                    return <ResultElement element={element} index={index} key={`result${index}`} />
                })}
            </Row>
            </>
        );
    }
}
export default Results;
