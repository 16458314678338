import 'react-app-polyfill/ie11';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import App from "./containers/app";

if (module.hot) {
	module.hot.accept();
}

ReactDOM.render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
	document.querySelector("#root")
);
registerServiceWorker();