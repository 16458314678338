import React, { Component } from 'react';
import { InputNumber } from 'rsuite';

class PercentInp extends Component {

    onData(value) {
        this.props.onData(value);
    }

    componentDidMount() {
        if (this.props.answer.value) {
            document.getElementById(`input${this.props.answer.id}`).value = this.props.answer.value;
        }
    }

    render() {
        const { answer } = this.props;

        return (
            <InputNumber
                id={`input${answer.id}`}
                placeholder={this.props.trigger ?
                    answer.value ? answer.value : "%" 
                    : 0}
                onChange={this.onData.bind(this)}
                max={100}
                min={0}
                step={5}
                className="percentInput"
                disabled={!this.props.trigger}
            />
        );
    }
};

export default PercentInp;
